import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isVisibleSidebar: false,
        isActivetedUser: true,
        isVisiblePopupNotification: false,
        isAuth: false,
        email: "",
        fio: "",
        phone: "",
        avatar: "",
        qrcodes: [],
        notificationList: [],
        firstNoReadenNotification: "",
    },
    getters: {},
    mutations: {
        changeVisibleSidebar(state, bool) {
            state.isVisibleSidebar = bool;
        },
        changeVisiblePopupNotification(state, bool) {
            state.isVisiblePopupNotification = bool;
        },
        isAcitvetedUser(state, bool) {
            state.isActivetedUser = bool;
        },
        changeFio(state, fio) {
            state.fio = fio;
        },
        changePhone(state, phone) {
            state.phone = phone;
        },
        changeEmail(state, email) {
            state.email = email;
        },
        changeAvatar(state, avatar) {
            state.avatar = avatar;
        },
        changeQrcodes(state, qrcodes) {
            state.qrcodes = qrcodes;
        },
        changeIsAuth(state, bool) {
            state.isAuth = bool;
        },
        changeNotifications(state, notifications) {
            if (state.notificationList.length !== notifications.length) {
                state.notificationList = notifications;
                this.commit("getFirstNoReadenNotification", notifications);
            } else {
                state.notificationList = notifications;
            }
        },
        getFirstNoReadenNotification(state, notificationList) {
            if (notificationList.length !== 0) {
                const index = notificationList.findLastIndex((item) => item.status === false);
                state.firstNoReadenNotification = notificationList[index].id;
                state.isVisiblePopupNotification = true;
            } else {
                state.firstNoReadenNotification = "";
                state.isVisiblePopupNotification = false;
            }
        },
        cleaningStore(state) {
            (state.isVisibleSidebar = false),
                (state.isActivetedUser = true),
                state.isVisiblePopupNotification == false,
                (state.isAuth = false);
            (state.email = ""),
                (state.fio = ""),
                (state.phone = ""),
                (state.avatar = ""),
                (state.qrcodes = []),
                (state.notificationList = []),
                (state.firstNoReadenNotification = "");
        },
    },
    actions: {
        async getUserData({ commit }) {
            try {
                const { data } = await axios.post(`${process.env.VUE_APP_MYHOST}/getuserdata`, {
                    token: Vue.$cookies.get("cng_token"),
                });

                data.status === "active"
                    ? commit("isAcitvetedUser", true)
                    : commit("isAcitvetedUser", false);

                commit("changeIsAuth", true);
                commit("changeEmail", data.email);
                commit("changePhone", data.phone);
                commit("changeFio", data.fio);
                commit("changeAvatar", data.avatar);
            } catch (error) {
                console.error(error.message);
            }
        },
        async changeStatusNotification({ commit }, id) {
            try {
                fetch(`${process.env.VUE_APP_NT}/notification`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: id,
                        status: true,
                    }),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.data !== undefined) {
                            const newNotificationList = [...this.state.notificationList];
                            newNotificationList.map((item) => {
                                if (item.id === id) {
                                    item.status = true;
                                }
                            });
                            commit("changeNotifications", newNotificationList);
                        }
                    });
            } catch (e) {
                console.log(e);
            }
        },
        async getUserNotification({ commit }) {
            try {
                const user_id = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
                fetch(`${process.env.VUE_APP_NT}/notification/${user_id}`, {
                    method: "GET",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.data !== undefined) {
                            commit("changeNotifications", data.data);
                        } else {
                            commit("changeNotifications", []);
                        }
                        // commit('getFirstNoReadenNotification', (data.data))
                    });
            } catch (e) {
                console.log(e);
            }
        },

        async getQrcodes({ commit }) {
            try {
                const user_id = jwt_decode(Vue.$cookies.get("cng_token"))["id"];
                const response = await fetch(`${process.env.VUE_APP_QR}/userchecks/${user_id}`, {
                    method: "GET",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.data !== undefined) {
                            commit("changeQrcodes", data.data);
                            return data.data;
                        } else {
                            commit("changeQrcodes", []);
                            return undefined;
                        }

                        // console.log(this.state.qrcodes)
                    });
                return response;
            } catch (e) {
                console.log(e);
            }
        },
        async test() {
            const a = await setTimeout(() => {
                console.log(123);
            }, 500).then(true);
            return a;
        },
    },
    modules: {},
});

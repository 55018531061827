<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <v-form class="apruve-container" ref="form" lazy-validation>
            <div class="title__wrap">
                <h1>Завершите регистрацию</h1>
                <p>Для участия в&nbsp;акции укажите ФИО и&nbsp;номер телефона для связи</p>
            </div>
            <div class="inputs">
                <v-text-field
                    v-model="fio"
                    label="ФИО"
                    autocomplete="name"
                    :rules="fioRules"
                    dense
                    outlined
                    class="fio-field email-field"
                    :class="{ 'error-input': this.fioRules.length > 0 }"
                    required
                    clearable
                >
                </v-text-field>
                <v-text-field
                    v-model="phone"
                    label="Телефон"
                    placeholder="+7"
                    :rules="phoneRules"
                    dense
                    v-mask="'+7 (###) ###-##-##'"
                    outlined
                    class="email-field"
                    :class="{ 'error-input': this.phoneRules.length > 0 }"
                    clearable
                    v-on:focus="focus"
                    v-on:focusout="focusout"
                    v-on:keydown.delete="
                        () => {
                            if (phone?.length <= 2) {
                                $event.preventDefault();
                            }
                        }
                    "
                    v-on:keydown.backspace="
                        () => {
                            if (phone?.length <= 2) {
                                $event.preventDefault();
                            }
                        }
                    "
                >
                </v-text-field>
            </div>
            <v-btn depressed :color="'primary'" class="next-btn" block @click="register"
                >Сохранить</v-btn
            >
        </v-form>
    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default {
    props: {},
    data() {
        return {
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
            fio: "",
            phone: "",
            phoneRules: [],
            fioRules: [],
        };
    },
    methods: {
        focus() {
            if (this.phone?.length <= 2 || this.phone === null) {
                this.phone = "+7";
            }
        },
        async focusout() {
            if (this.phone?.length <= 2 || this.phone === null) {
                console.log(this.phone);
                this.phone = "";
                await this.$nextTick();
                this.phone = "";
            }
        },
        register: async function () {
            function containsNumbers(str) {
                return /\d/.test(str);
            }
            let phone_numeric = this.phone.match(/\d/g);
            if (this.phone !== null && phone_numeric?.length != 11) {
                this.phoneRules = ["Некорректный номер телефона"];
                if (containsNumbers(this.fio) || this.fio === "" || this.fio === null) {
                    this.fioRules = ["Некорректное ФИО"];
                    return;
                } else {
                    this.fioRules = [];
                }
                return;
            }
            if (containsNumbers(this.fio) || this.fio === "" || this.fio === null) {
                this.fioRules = ["Некорректное ФИО"];
                return;
            } else {
                this.fioRules = [];
            }

            try {
                const { data } = await axios.patch(`${process.env.VUE_APP_MYHOST}`, {
                    id: this.user_id,
                    phone: this.phone,
                    fio: this.fio,
                });

                this.$store.commit("isAcitvetedUser", true);
                this.$cookies.set("cng_token", data.jwt);
                this.$router.push({ name: "LcMain" });
            } catch (error) {
                console.error(error.message);
            }
        },
    },
    watch: {
        async phone() {
            if (this.phone === null) {
                console.log("null");
                await this.$nextTick();
                this.phone = "+7";
            }
            this.phoneRules = [];
        },
        fio() {
            this.fioRules = [];
        },
    },
    mounted() {},
    components: { HeaderNav },
};
</script>

<style lang="scss" scoped>
.apruve-container {
    gap: 0px !important;
}
.wrapper {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
.title__wrap {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 48px;
}
.title__wrap p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1a1a1a;
}
.inputs .fio-field {
    margin-bottom: 16px;
}
.v-form h1 {
    font-family: "PT Sans";
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.005em;
    text-align: center;
}

.v-form,
.form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.v-form {
    background: #fff;
    width: 100%;
    padding: 32px 20px;
    border-radius: 16px;
}
.inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    gap: 0px;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    margin-bottom: 6px !important;
}

.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
}
.politic {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
}
.politic a {
    color: #868686;
}
</style>

<template>
    <div class="v-form v-form__downl">
        <h1 class="upload__title">Не удалось отсканировать QR&#8209;код?</h1>
        <p class="upload__text">Загрузите фото чека или&nbsp;введите&nbsp;данные&nbsp;вручную</p>
        <div class="accept__btns">
            <div>
                <input
                    type="file"
                    id="qr"
                    accept=".png,.jpg,.jpeg"
                    capture="file"
                    ref="uploader"
                    @change="onFileChanged"
                />
                <v-btn
                    depressed
                    color="primary"
                    class="next-btn btn__download accept__btn"
                    block
                    @click="handleFileImport"
                >
                    <div class="btn-content-container">
                        <img
                            src="@/assets/loader.svg"
                            alt="loader"
                            class="loader-icon"
                            :class="{ hidden: !isLoading }"
                        />
                        Загрузить фото чека
                    </div></v-btn
                >
            </div>

            <div>
                <v-btn
                    depressed
                    color="primary"
                    class="next-btn"
                    block
                    @click="$router.push({ name: 'LcManual' })"
                    ><div class="btn-content-container">
                        <img
                            src="@/assets/loader.svg"
                            alt="loader"
                            class="loader-icon"
                            :class="{ hidden: !isLoading }"
                        />
                        Ввести вручную
                    </div></v-btn
                >
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";
import QrScanner from "qr-scanner";

export default {
    data() {
        return {
            isLoading: false,
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
        };
    },
    methods: {
        handleFileImport() {
            this.$refs.uploader.click();
        },

        async onFileChanged(e) {
            try {
                const formData = new FormData();

                const qrcodeData = QrScanner.scanImage(e.target.files[0])
                    .then((str) => {
                        const kv = str.split("&");
                        const res = {};

                        for (let i = 0; i < kv.length; i++) {
                            let tmp = kv[i].split("=");
                            res[tmp[0]] = tmp[1];
                        }

                        return JSON.stringify({
                            fn: res.fn,
                            fp: res.fp,
                            fd: res.i,
                            summary: res.s,
                            time: res.t,
                            operation: res.n,
                        });
                    })
                    .catch((error) => console.log(error || "No QR code found."));

                formData.append("file", e.target.files[0]);
                formData.append("accountId", this.user_id);
                formData.append("qrcodeData", await qrcodeData);
                formData.append("type", "photo");

                await axios.post(`${process.env.VUE_APP_QR}/save-check-photo`, formData);

                this.$store.state.isActivetedUser
                    ? this.$router.push({ name: "LcSuccess" })
                    : this.$router.push({ name: "first_check" });
            } catch (error) {
                console.error(error.message);
                alert("Only png jpg jpeg files require!!!");
            }
        },

        decodeStr(str) {
            console.log(str);
            try {
                let kv = str.split("&");
                let res = {};
                for (let i = 0; i < kv.length; i++) {
                    let tmp = kv[i].split("=");
                    res[tmp[0]] = tmp[1];
                }
                return res;
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        ...mapActions({
            getUserNotification: "getUserNotification",
        }),
    },
};
</script>

<style scoped>
.v-form.v-form__downl {
    gap: 24px;
    min-height: 200px !important;
}

.upload__title {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #1a1a1a;
    margin-bottom: 18px;
}

.upload__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1a1a1a;
    margin-bottom: 28px !important;
}

.accept__btns {
    gap: 0 !important;
}

.next-btn.btn__download.accept__btn {
    margin-bottom: 4px;
}
</style>
